export const useEnrollmentDynamicFields = () => {
  const { enrollmentDynamicFields } = useAppStore()

  const isFieldRequired = (field: string) => {
    return enrollmentDynamicFields?.find(value => value.code === field)?.required ?? false
  }

  const getDefaultValue = (field: string) => {
    return enrollmentDynamicFields?.find(value => value.code === field)?.defaultValue
  }

  const birthDateStrField = computed(() => {
    return enrollmentDynamicFields?.find(({ code }) => code === 'birthDateStr')
  })

  const documentTypeValues = computed(() => {
    return enrollmentDynamicFields?.find(({ code }) => code === 'documentType')?.values ?? []
  })

  return { isFieldRequired, getDefaultValue, birthDateStrField, documentTypeValues }
}
